.logo-teasers__inner {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo-teasers__logo-teaser {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));

  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .logo-teasers--3 & {
    @include use-responsive-sizing(width, (
      xs: 100%,
      s: 50%,
      m: 33.33%,
    ));
  }

  .logo-teasers--4 & {
    @include use-responsive-sizing(width, (
      xs: 100%,
      s: 50%,
      m: 25%,
    ));
  }
}

