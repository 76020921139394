.image {
  display: inline-block;
  line-height: 0;
  position: relative;
}

.image--cover {
  flex-grow: 1;
}

.image__link {
  display: block;
  line-height: 0;
}

.image__img {
  font-size: 1.4rem;
  height: auto;
  max-width: 100%;
  position: relative;
  z-index: 1;

  .image--cover & {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.image__copyright {
  background-color: rgba(0, 0, 0, 0.55);
  bottom: 0;
  color: $color-white;
  font-size: 1.1rem;
  line-height: 1.2;
  max-width: 100%;
  padding: 0.3rem 0.5rem;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 2;

  .link {
    color: $color-white;

    &:focus {
      outline-color: #fff;
    }
  }
}

.image__copyright--flipped-mobile {
  @include mq($until: l) {
    bottom: 0;
    transform: rotate(-90deg) translate(100%, 0);
    transform-origin: right bottom;
  }
}
