.headline {
  font-weight: bold;
  line-height: 1.2;
}

.headline--1 {
  @include use-responsive-sizing(font-size, (
    xs: 4rem,
    s: 4.4rem,
    m: 4.8rem,
    l: 5.4rem,
    xl: 5.6rem,
  ));
}

.headline--2 {
  @include use-responsive-sizing(font-size, (
    xs: 3.2rem,
    s: 3.4rem,
    m: 3.6rem,
    l: 3.8rem,
    xl: 4rem,
  ));
}

.headline--3 {
  @include use-responsive-sizing(font-size, (
    xs: 2.6rem,
    s: 3rem,
    m: 3.2rem,
    l: 3.4rem,
    xl: 3.6rem,
  ));
}

.headline--4 {
  @include use-responsive-sizing(font-size, (
    xs: 2.4rem,
    m: 2.6rem,
    l: 2.8rem,
  ));
}

.headline--5 {
  font-size: 2rem;
}

.headline__link {
  display: block;
}

.headline__text {
  .headline--teal & {
    color: $color-teal-1;
  }

  .headline--green & {
    color: $color-green-1;
  }
}

.headline__kicker {
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 1.2rem;
}
