.intro {
  text-align: center;
}

.intro__text {
  @include use-responsive-sizing(font-size, (
    xs: 2.2rem,
    s: 2.4rem,
    m: 2.8rem,
    l: 3rem,
    xl: 3.2rem,
  ));

  line-height: 1.6;
}

.intro__button {
  margin-top: 3.2rem;
}
