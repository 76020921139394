.section {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
    l: 7.2rem,
    xl: 9.6rem,
  ));

  &:not(.section--with-background) + &:not(.section--with-background),
  &.section--with-background + &.section--with-background {
    padding-top: 0;
  }
}

.section--with-background {
  background-color: $color-green-4;
}

.section__inner {
  @include use-container();
}

.section__headline {
  @include use-responsive-sizing(margin-bottom, (
    xs: 4rem,
    l: 4.8rem,
  ));

  text-align: center;
}

.section__content {
  margin-left: auto;
  margin-right: auto;

  .section--narrow & {
    @include use-responsive-sizing(width, (
      l: column-width(l, 10),
      xl: column-width(xl, 10),
    ));
  }
}

.section__button {
  @include use-responsive-sizing(margin-top, (
    xs: 4rem,
    l: 4.8rem,
  ));

  text-align: center;
}
