.stage {
  background-color: $color-teal-3;
  position: relative;
}

.stage__slides {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;

  &:focus {
    outline: 0;
  }

  &::after {
    @include use-outline(-10px, $color-white);

    content: '';
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    position: none;
    z-index: 5;
  }

  &:focus::after {
    opacity: 1;
  }

  &:focus:not(:focus-visible)::after {
    opacity: 0;
  }
}

.stage__slide {
  @include use-responsive-sizing(padding-bottom, $inner-container-padding, xs, m, true);

  flex-shrink: 0;
  min-height: 60rem;
  position: relative;
  width: 100%;
}

.stage__image {
  @include use-responsive-sizing(height, (
    xs: 28rem,
    s: 36rem,
    m: 40rem,
    l: 60rem,
  ));

  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.stage__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top, (
    xs: 20rem,
    s: 24rem,
    m: 24rem,
    l: 7rem,
  ));

  position: relative;
  z-index: 2;
}

.stage__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 8),
    l: column-width(l, 6),
    xl: column-width(xl, 6),
  ));

  background-color: $color-teal-1;
  border-bottom-right-radius: 20rem;
  border-top-right-radius: 20rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 40rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3.2rem 5.6rem 3.2rem 3.2rem;
  position: relative;

  @include mq($from: l) {
    border-bottom-right-radius: 23rem;
    border-top-right-radius: 23rem;
    height: 46rem;
    margin-left: 0;
    padding-right: 8rem;
  }
}

.stage__description {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 1.6rem;

  @include mq($from: m) {
    font-size: 2rem;
  }
}

.stage__more-link {
  margin-top: 1.6rem;
}

.stage__navigation {
  @include use-responsive-sizing(height, (
    xs: 28rem,
    s: 36rem,
    m: 40rem,
    l: 60rem,
  ));

  align-items: center;
  display: flex;
  inset: 0;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.stage__navigation-button {
  color: $color-white;
  font-size: 4.8rem;
  line-height: 0;
  padding: 1.6rem;
  pointer-events: all;
  transition-property: background-color, opacity;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-black;
  }
}

.stage__navigation-indicators {
  display: flex;
  justify-content: center;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
}

.stage__navigation-indicator {
  background-color: $color-teal-1;
  border-radius: 50%;
  height: 2rem;
  opacity: 0.3;
  pointer-events: all;
  transition-property: opacity;
  width: 2rem;

  &[disabled] {
    pointer-events: none;
  }

  &[disabled],
  &:hover,
  &:focus {
    opacity: 1;
  }

  & + & {
    margin-left: 1.6rem;
  }
}
