@keyframes show-navigation-dropdown {
  0% {
    opacity: 0;
    transform: translate(-50%, -1.6rem);
  }

  50% {
    transform: translate(-50%, 0);
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-search {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  50% {
    transform: translateY(1.6rem);
  }

  100% {
    opacity: 1;
  }
}

.header {
  @include use-responsive-sizing(min-height, (
    xs: 9.6rem,
    l: 13.7rem,
    xl: 15.55rem,
  ));

  pointer-events: none;
  position: sticky;
  top: 0;
  z-index: z-index('header');
}

.header__sticky {
  background-color: $color-white;
  border-bottom: 2px solid $color-teal-1;
  pointer-events: all;
}

.header__inner {
  @include use-container();

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  transition-property: padding;
}

.header__logo-wrapper {
  flex-shrink: 0;
  line-height: 0;
  margin-right: auto;
}

.header__logo {
  display: block;
}

.header__logo-img {
  height: auto;
  max-width: 100%;
}

.header__menu-search {
  align-items: center;
  display: flex;
}

.header__languages {
  display: flex;
  font-size: 1.4rem;
}

.header__languages-list {
  align-self: center;
  display: flex;
  line-height: 1.1;
}

.header__languages-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.8rem;
  opacity: 0.5;
}

.header__languages-item {
  display: block;

  & + & {
    margin-left: 1.6rem;
  }
}

.header__navigation-link {
  user-select: none;
}

.header__navigation-link-indicator {
  font-size: 1.4rem;
  margin-left: 0.8rem;
}

.header__search-overlay {
  animation: show-search 0.4s;
  background-color: $color-teal-1;
  outline: 3px solid $color-white;
  padding: 2.4rem;
  position: absolute;
  transform: translateY(1.6rem);
  z-index: z-index('menu');

  &::before {
    border-bottom: 0.8rem solid $color-teal-1;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    content: '';
    position: absolute;
    right: 1.6rem;
    top: -0.8rem;
  }
}

@include mq($until: l) {
  .header__inner {
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;

    .header--scrolled & {
      padding-bottom: 1.6rem;
      padding-top: 1.6rem;
    }
  }

  .header__logo {
    width: 11.5rem;
  }

  .header__navigations {
    background-color: $color-teal-1;
    color: #fff;
    display: none;
    inset: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: z-index('menu');
  }

  .header__navigations--open {
    display: block;
  }

  .header__close-button-container {
    @include use-responsive-sizing(padding, $inner-container-padding);

    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .header__navigations-inner {
    @include use-container();

    padding-bottom: calc(5vw + 8rem);
    padding-top: calc(5vw + 8rem);
  }

  .header__languages {
    align-items: center;
    margin-bottom: 3.2rem;
    margin-top: -8rem;
    min-height: 4.8rem;
  }

  .header__navigation-item {
    display: block;

    & + & {
      margin-top: 2.4rem;
    }
  }

  .header__navigation-link {
    display: block;
    font-size: 2rem;
    font-weight: bold;
  }

  .header__navigation-dropdown {
    padding-top: 1.2rem;
  }

  .header__navigation-dropdown-item {
    display: block;
  }

  .header__navigation-dropdown-link {
    display: block;
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
  }

  .header__search {
    margin-left: 1.6rem;
  }

  .header__search-overlay {
    @include use-responsive-sizing(left right, $inner-container-padding);
  }
}

@include mq($from: l) {
  .header__inner {
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;

    .header--scrolled & {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .header__inner {
    flex-wrap: nowrap;
  }

  .header__menu-search {
    position: relative;
  }

  .header__menu-search--has-languages {
    padding-top: 4rem;
    transition-property: transform;

    .header--scrolled & {
      transform: translateY(-2rem);
    }
  }

  .header__languages {
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    transition-property: transform;

    .header--scrolled & {
      transform: translateY(-100%);
    }
  }

  .header__logo {
    transform-origin: 0 50%;
    transition-property: transform;
    width: 17rem;

    .header--scrolled & {
      transform: scale(0.7);
    }
  }

  .header__menu-button,
  .header__close-button {
    display: none;
  }

  .header__navigation {
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
  }

  .header__navigation-item {
    display: block;
    position: relative;

    & + & {
      margin-left: 1.6rem;
    }
  }

  .header__navigation-link {
    display: block;
    font-size: 2rem;
    white-space: nowrap;
  }

  .header__navigation-dropdown {
    animation: show-navigation-dropdown 0.4s;
    background-color: $color-teal-1;
    color: $color-white;
    display: none;
    left: 50%;
    outline: 3px solid $color-white;
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
    position: absolute;
    top: calc(100% + 2.4rem);
    transform: translateX(-50%);
    width: 25rem;
    z-index: z-index('menu');

    .header__navigation-item--open & {
      display: block;
    }

    &::before {
      border-bottom: 0.8rem solid $color-teal-1;
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      content: '';
      left: 50%;
      position: absolute;
      top: -0.8rem;
      transform: translateX(-50%);
    }
  }

  .header__navigation-dropdown-item {
    display: block;
  }

  .header__navigation-dropdown-link {
    display: block;
    font-size: 1.6rem;
    padding: 1.2rem 2rem;
  }

  .header__menu-search {
    justify-content: space-between;
    width: auto;
  }

  .header__search {
    margin-left: 1.6rem;
  }

  .header__search-overlay {
    @include use-responsive-sizing(right, $inner-container-padding);

    width: 50rem;
  }

  .header__navigation-item {
    display: block;

    & + & {
      margin-left: 2.4rem;
    }
  }

  .header__search {
    margin-left: 3.2rem;
  }
}

@include mq($from: xl) {
  .header__logo {
    width: 21.5rem;
  }

  .header__navigation-item {
    & + & {
      margin-left: 3.2rem;
    }
  }

  .header__search {
    margin-left: 5.6rem;
  }
}

@include mq($from: rem2px($inner-container-max-width)) {
  .header__search-overlay {
    right: $inner-container-max-padding;
  }
}
