.page {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 200rem;
  min-height: 100%;
  position: relative;
}
