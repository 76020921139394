.person-slider__inner {
  @include use-break-out();

  padding-left: 2.4rem;
  padding-right: 2.4rem;
  position: relative;

  @include mq($from: m) {
    padding-left: 0;
    padding-right: 0;
  }
}

.person-slider__track {
  @include use-container();

  position: relative;
}

.person-slider__slides {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  scroll-behavior: smooth;

  &:focus {
    outline: 0;
  }

  &::after {
    @include use-outline(-10px, $color-black);

    content: '';
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    position: none;
    z-index: 5;
  }

  &:focus::after {
    opacity: 1;
  }

  &:focus:not(:focus-visible)::after {
    opacity: 0;
  }
}

.person-slider__slide {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: 50%,
    l: 33.33%,
  ));

  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.person-slider__person {
  align-items: flex-start;
  display: flex;
}

.person-slider__image {
  border-radius: 50%;
  line-height: 0;
  margin-bottom: 3.2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  overflow: hidden;
}

.person-slider__description {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 0.4rem;
}

.person-slider__quote {
  @include use-responsive-sizing(font-size, (
    xs: 1.6rem,
    m: 2rem,
  ));

  color: $color-green-1;
  display: block;
  font-style: italic;
  line-height: 1.6;
  margin-top: 2.4rem;
  quotes: '„' '“' '‚' '‘';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.person-slider__copyright {
  color: rgba($color-black, $alpha: 0.6);
  font-size: 1.1rem;
  line-height: 1.2;
  margin-top: 2.4rem;
}

.person-slider__navigation {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.person-slider__navigation-button {
  color: $color-black;
  font-size: 4.8rem;
  line-height: 0;
  padding: 0.8rem;
  pointer-events: all;
  transition-property: background-color, color, opacity;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
  }
}
