@keyframes show-consent-banner {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.consent-banner {
  animation: show-consent-banner 0.2s;
  background-color: $color-teal-1;
  bottom: 0;
  color: $color-white;
  display: block;
  left: 0;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: sticky;
  right: 0;
  z-index: z-index('consent-banner');

  &[hidden] {
    display: none;
  }
}

.consent-banner--hidden {
  display: none;
}

.consent-banner__inner {
  @include use-container();

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  @include mq($from: m) {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.consent-banner__text {
  flex-grow: 2;
  font-size: 1.6rem;
  line-height: 1.6;

  .link {
    color: #fff;
  }

  @include mq($from: m) {
    font-size: 1.9rem;
    margin-right: 4.8rem;
    text-align: left;
  }
}

.consent-banner__button {
  flex-shrink: 0;
  margin-top: 2.4rem;

  & + & {
    margin-left: 2.4rem;
  }

  @include mq($from: m) {
    margin-top: 0;
  }
}
