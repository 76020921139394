.teasers--list {
  .teasers__teaser {
    @include use-responsive-sizing(padding-top padding-bottom, $gaps);

    border-bottom: 1px solid rgba($color-black, 0.2);
    border-top: 1px solid rgba($color-black, 0.2);
  }

  .teasers__teaser + .teasers__teaser {
    border-top: 0;
  }
}

.teasers--grid {
  .teasers__inner {
    @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .teasers__teaser {
    @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));

    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;

    @include mq($from: s) {
      width: 50%;
    }
  }
}

.teasers__pagination {
  margin-top: 4.8rem;
}
