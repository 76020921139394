.button {
  background-color: $color-green-2;
  border-bottom-right-radius: 2.4rem;
  border-top-right-radius: 2.4rem;
  color: $color-white;
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  height: 4.8rem;
  line-height: 2rem;
  padding: 1.4rem 2rem 1.4rem 1.6rem;
  transition-property: background-color, color;
  user-select: none;

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-black;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  @include mq($from: m) {
    border-bottom-right-radius: 2.8rem;
    border-top-right-radius: 2.8rem;
    font-size: 2rem;
    height: 5.6rem;
    line-height: 2.4rem;
    padding: 1.6rem 2.6rem 1.6rem 2rem;
  }
}
