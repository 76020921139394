.form__error {
  color: $color-red;
  font-weight: bold;
  margin-bottom: 2.4rem;
}

.form__headline {
  margin-bottom: 4rem;
}

.form__field {
  & + & {
    margin-top: 3.2rem;
  }

  & + &.form__field--headline {
    margin-top: 5.6rem;
  }
}

.form__submit {
  margin-top: 4rem;
  text-align: center;
}
