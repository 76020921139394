@font-face {
  font-display: swap;
  font-family: 'Objectivity';
  src: url('../../fonts/objectivity-regular.woff2') format('woff2'), url('../../fonts/objectivity-regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Objectivity';
  font-style: italic;
  src: url('../../fonts/objectivity-regular-slanted.woff2') format('woff2'), url('../../fonts/objectivity-regular-slanted.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Objectivity';
  font-weight: bold;
  src: url('../../fonts/objectivity-bold.woff2') format('woff2'), url('../../fonts/objectivity-bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Objectivity';
  font-style: italic;
  font-weight: bold;
  src: url('../../fonts/objectivity-bold-slanted.woff2') format('woff2'), url('../../fonts/objectivity-bold-slanted.woff') format('woff');
}
