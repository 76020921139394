.u-hidden-visually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.u-skip-link {
  background-color: $color-teal-1;
  color: $color-white;
  font-size: 2rem;
  font-weight: bold;
  left: 0;
  outline: 0;
  padding: 1.6rem 3.2rem;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transform: translateY(-500%);
  z-index: z-index('skip-link');

  &:hover,
  &:focus,
  &:active {
    transform: translateY(0);
  }
}

.u-overlay {
  &::after {
    bottom: 0;
    content: '';
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    z-index: 2;
  }
}

.u-overlay-push {
  position: relative;
  z-index: 3;
}

.u-overlay-padding {
  &::after {
    bottom: -2rem;
    content: '';
    left: -2rem;
    overflow: hidden;
    position: absolute;
    right: -2rem;
    top: -2rem;
    white-space: nowrap;
    z-index: 2;
  }
}

.u-underline-focus {
  box-shadow: inset 0 -1px 0 0 transparent;
  position: relative;
  transition-property: box-shadow;

  button:not([disabled]):hover &,
  button:not(.u-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.u-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.u-invisible-focus):focus &,
  .has-underline:not(.u-invisible-focus):focus-within & {
    box-shadow: inset 0 -1px 0 0 currentColor;
  }
}

.u-underline {
  box-shadow: inset 0 -1px 0 0 currentColor;
  position: relative;
  transition-property: box-shadow;

  button:not([disabled]):hover &,
  button:not(.u-invisible-focus):focus &,
  :any-link:hover &,
  :any-link:not(.u-invisible-focus):focus &,
  .has-underline:hover &,
  .has-underline:not(.u-invisible-focus):focus &,
  .has-underline:not(.u-invisible-focus):focus-within & {
    box-shadow: inset 0 -1px 0 0 transparent;
  }
}
