.teaser {
  position: relative;
}

.teaser--list {
  @include mq($from: s) {
    display: flex;
  }
}

.teaser__image {
  flex-basis: 50%;
}

.teaser__content {
  flex-basis: 100%;

  .teaser__image + & {
    flex-basis: 50%;
    margin-top: 1.6rem;
  }

  .teaser--list .teaser__image + & {
    @include use-responsive-sizing(margin-left, $gaps, s);

    @include mq($from: s) {
      margin-top: 0;
    }
  }
}

.teaser__date {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.teaser__description {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 1.6rem;

  @include mq($from: m) {
    font-size: 1.9rem;
  }
}

.teaser__more-link {
  margin-top: 1.6rem;
}
