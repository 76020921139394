html {
  color: $color-black;
  font-family: $font-stack;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  height: 100%;
  line-height: 1;
  min-height: 100%;
  scroll-behavior: smooth;
  scroll-padding-bottom: 15rem;
  scroll-padding-top: 15rem;
}
