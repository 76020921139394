.quote {
  @include mq($from: m) {
    align-items: center;
    display: flex;
  }
}

.quote__image {
  @include use-responsive-sizing(margin-bottom, (xs: 3.2rem, m: 0));
  @include use-responsive-sizing(margin-right, $gaps, m);
  @include use-responsive-sizing(width, (
    xs: 15rem,
    s: 18rem,
    m: column-width(m, 4),
    l: column-width(l, 2, 10),
    xl: column-width(xl, 2, 10),
  ));

  border-radius: 50%;
  flex-shrink: 0;
  line-height: 0;
  margin-bottom: 3.2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  overflow: hidden;
}

.quote__content {
  line-height: 1.6;
  text-align: center;

  @include mq($from: m) {
    text-align: left;
  }
}

.quote__quote {
  @include use-responsive-sizing(font-size, (
    xs: 2.2rem,
    m: 2.6rem,
  ));

  color: $color-green-1;
  display: block;
  font-style: italic;
  quotes: '„' '“' '‚' '‘';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.quote__caption {
  display: block;
  font-size: 1.6rem;
  margin-top: 1.2rem;

  &::before {
    background-color: currentColor;
    content: '';
    display: inline-block;
    height: 1px;
    margin-right: 1.6rem;
    vertical-align: middle;
    width: 2rem;
  }
}

.quote__copyright {
  color: rgba($color-black, $alpha: 0.6);
  font-size: 1.1rem;
  line-height: 1.2;
  margin-top: 1.2rem;

  @include mq($from: m) {
    margin-top: 1.6rem;
  }
}
