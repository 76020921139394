.breaker {
  @include use-responsive-sizing(padding-bottom, $inner-container-padding, xs, s, true);

  background-color: $color-teal-3;
  min-height: 45rem;
  position: relative;
}

.breaker__image {
  display: flex;
  flex-direction: column;
  height: 45rem;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.breaker__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top, (
    xs: 25rem,
    m: 6.5rem,
  ));

  position: relative;
  z-index: 2;
}

.breaker__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 7),
    l: column-width(l, 6),
    xl: column-width(xl, 5),
  ));

  background-color: $color-teal-1;
  border-bottom-right-radius: 16rem;
  border-top-right-radius: 16rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 32rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3.2rem 6rem 3.2rem 3.2rem;
  position: relative;

  @include mq($from: m) {
    margin-left: 0;
    padding-right: 8rem;
  }
}

.breaker__more-link {
  margin-top: 1.6rem;
}
