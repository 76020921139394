.text-breaker {
  background-color: $color-green-4;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.text-breaker__inner {
  @include use-container();
}

.text-breaker__content {
  @include use-responsive-sizing(width, (
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;

  @include mq($from: m) {
    align-items: flex-start;
    display: flex;
  }
}

.text-breaker__image {
  @include use-responsive-sizing(margin-bottom, (xs: 3.2rem, m: 0));
  @include use-responsive-sizing(margin-right, $gaps, m);
  @include use-responsive-sizing(width, (
    xs: 15rem,
    s: 18rem,
    m: column-width(m, 4, 10),
    l: column-width(l, 2, 8),
    xl: column-width(xl, 2, 8),
  ));

  border-radius: 50%;
  flex-shrink: 0;
  line-height: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  overflow: hidden;
}

.text-breaker__text {
  font-size: 1.6rem;
  line-height: 1.6;
  width: 100%;

  @include mq($from: m) {
    font-size: 1.9rem;
  }
}

.text-breaker__copyright {
  color: rgba($color-black, $alpha: 0.6);
  font-size: 1.1rem;
  line-height: 1.2;
  margin-top: 2.4rem;
}
