.icon-button {
  color: $color-white;
  display: inline-block;
  font-size: 2.4rem;
  height: 4.8rem;
  line-height: 0;
  padding: 1.2rem;
  position: relative;
  transition-property: background-color;
  user-select: none;
  width: 4.8rem;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-black;
  }
}

.icon-button--1 {
  background-color: $color-green-3;
  border-radius: 50%;
}

.icon-button--2 {
  background-color: $color-teal-1;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;

  .icon-button__icon {
    transform: translateX(-0.2rem);
  }
}

.icon-button--3 {
  background-color: $color-teal-2;
}

.icon-button--4 {
  background-color: $color-green-2;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
