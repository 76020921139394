.preview {
  min-height: 100vh;
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview--teal-1 {
  background-color: $color-teal-1;
}
