.footer {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
    l: 7.2rem,
    xl: 9.6rem,
  ));

  background-color: $color-teal-3;
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: auto;

  @include mq($from: l) {
    font-size: 2rem;
  }
}

.footer__inner {
  @include use-container();
}

.footer__contact {
  .link {
    color: $color-black;
  }

  @include mq($from: l) {
    display: flex;
  }
}

.footer__logo {
  display: block;
  flex-shrink: 0;
  line-height: 0;
  width: 21.5rem;
}

.footer__contact-details {
  margin-top: 1.6rem;

  @include mq($from: l) {
    margin-left: map-get($gaps, l);
    margin-top: 0;
  }

  @include mq($from: xl) {
    margin-left: map-get($gaps, xl);
  }
}


.footer__social-media,
.footer__sitemap,
.footer__meta-navigation {
  @include use-responsive-sizing(margin-top, (
    xs: 4rem,
    m: 4.8rem,
    l: 6.4rem,
    xl: 7.2rem,
  ));
}

.footer__social-media {
  @include use-responsive-sizing(padding-left, $gaps, l);

  @include mq($from: l) {
    flex-shrink: 0;
    margin-left: auto;
    margin-top: 0;
  }
}

.footer__social-media-heading {
  font-weight: bold;
  margin-bottom: 0.8rem;
}

.footer__social-media-links {
  display: flex;
}

.footer__social-media-link {
  display: block;

  & + & {
    margin-left: 1.6rem;
  }
}

.footer__sitemap {
  @include use-responsive-sizing(width, (
    l: column-width(l, 10),
    xl: column-width(xl, 10),
  ));
}

.footer__navigation {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.footer__navigation-item {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: 50%,
    m: 25%,
  ));

  display: block;
  flex-shrink: 0;
}

.footer__navigation-link {
  display: block;
  font-weight: bold;
}

.footer__subnavigation {
  font-size: 1.6rem;
  margin-top: 0.8rem;
}

.footer__subnavigation-item {
  display: block;

  & + & {
    margin-top: 0.4rem;
  }
}

.footer__subnavigation-link {
  display: block;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

.footer__meta-navigation {
  display: flex;
  flex-direction: column;

  @include mq($from: s) {
    flex-direction: row;
  }

  @include mq($from: l) {
    font-size: 1.6rem;
  }
}

.footer__meta-navigation-item {
  display: block;

  & + & {
    margin-top: 0.4rem;

    @include mq($from: s) {
      margin-left: 1.6rem;
      margin-top: 0;
    }
  }
}
