.more-button {
  align-items: center;
  display: inline-flex;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include mq($from: m) {
    font-size: 1.9rem;
  }
}

.more-button__icon {
  font-size: 1.2rem;
  line-height: 0;
  margin-left: 0.6rem;

  @include mq($from: m) {
    font-size: 1.4rem;
    margin-left: 0.8rem;
  }
}
