$field-box-shadow-hover-default: 0 0 0.5rem 0 transparent;
$field-box-shadow-hover: 0 0 0.5rem 0 rgba($color-black, 0.4);
$field-box-shadow-focus-default: 0 0 0 0.1rem transparent;
$field-box-shadow-focus: 0 0 0 0.1rem $color-black;

.field {
  line-height: 1;
}

.field__label {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 1.2rem;
}

.field__input-wrapper {
  position: relative;
}

.field__input {
  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 0.3rem transparent, inset 0 0 0 0.2rem $color-black;
  color: $color-black;
  display: block;
  font-size: 1.6rem;
  height: 4.8rem;
  line-height: 2rem;
  max-width: 100%;
  min-width: 100%;
  padding: 0;
  padding: 1.4rem 2rem;
  position: relative;
  transition-property: box-shadow;
  width: 100%;
  z-index: 2;

  &:focus {
    outline: 0;
  }

  &[rows] {
    height: auto;
  }

  &:hover {
    box-shadow: 0 0 0 0.2rem transparent, inset 0 0 0 0.2rem $color-teal-1;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $color-teal-1, inset 0 0 0 0.2rem $color-teal-1;
  }

  @include mq($from: m) {
    font-size: 1.9rem;
    height: 5.6rem;
    line-height: 2.4rem;
    padding: 1.6rem 2.4rem;
  }
}

.field__input--select {
  cursor: pointer;
}

.field__input--has-icon {
  padding-right: 2.4rem;
}

.field__icon {
  font-size: 1.8rem;
  height: 1.8rem;
  line-height: 0;
  pointer-events: none;
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
  z-index: 3;
}

.field__line {
  cursor: pointer;
  display: flex;
  user-select: none;

  &:focus-within {
    @include use-outline();
  }

  & + & {
    margin-top: 0.8rem;
  }
}

.field__checkbox,
.field__radio {
  flex-shrink: 0;
  position: relative;
}

.field__checkbox-input,
.field__radio-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;

  &:focus {
    outline: 0;
  }
}

.field__checkbox-icon {
  border-radius: 0.3rem;
  box-shadow: inset 0 0 0 0.2rem $color-black;
  color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.4rem;
  transition-property: background-color, color;
  user-select: none;
  width: 2.4rem;

  .field__line:hover & {
    color: rgba($color-black, 0.2);
  }

  .field__checkbox-input:checked ~ & {
    background-color: $color-black;
    color: $color-white;
  }
}

.field__radio-icon {
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.2rem $color-black;
  cursor: pointer;
  font-size: 1.4rem;
  height: 2.4rem;
  line-height: 0;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  width: 2.4rem;

  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    height: 1.4rem;
    left: 0.5rem;
    opacity: 0;
    position: absolute;
    top: 0.5rem;
    transition-property: opacity;
    width: 1.4rem;
  }

  .field__line:hover &::after {
    opacity: 0.2;
  }

  .field__radio-input:checked ~ &::after {
    opacity: 1;
  }
}

.field__line-label {
  flex-grow: 2;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-left: 1.2rem;
}

.field__error,
.field__description {
  display: flex;
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: 1.2rem;
}

.field__error {
  color: $color-red;
  font-weight: bold;
}
