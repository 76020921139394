.inline-form__field-button {
  @include mq($from: s) {
    align-items: flex-end;
    display: flex;
  }
}

.inline-form__field {
  flex-grow: 1;

  .field__input {
    border-radius: 0;

    &:focus {
      @include use-outline();
    }
  }
}

.inline-form__button {
  flex-shrink: 0;
  margin-top: 1.6rem;

  @include mq($from: s) {
    margin-top: 0;
  }
}

.inline-form__confirm {
  margin-top: 2.4rem;
}

.inline-form--transparent {
  color: $color-white;

  .link {
    color: $color-white;
  }

  .field__input {
    box-shadow: none;
  }

  .field__checkbox-icon {
    box-shadow: inset 0 0 0 0.2rem $color-white;
  }

  .field__line:hover .field__checkbox-icon {
    color: rgba($color-white, 0.2);
  }

  .field__checkbox-input:checked ~ .field__checkbox-icon {
    background-color: $color-white;
    color: $color-teal-1;
  }
}
