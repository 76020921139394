.link {
  color: $color-teal-1;

  .c-white & {
    color: $color-white;
  }
}

.link__icon {
  margin-right: 0.8rem;
}
