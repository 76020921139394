.article__image {
  @include use-responsive-sizing(height, (
    xs: 28rem,
    s: 36rem,
    m: 40rem,
    l: 45rem,
  ));

  display: flex;
  flex-direction: column;
}

.article__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
    l: 7.2rem,
    xl: 9.6rem,
  ));
}

.article__header,
.article__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
}

.article__header {
  @include use-responsive-sizing(margin-bottom, (
    xs: 4rem,
    s: 5.6rem,
    l: 6.4rem,
  ));

  text-align: center;
}

.article__date {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

.article__description {
  @include use-responsive-sizing(padding-bottom, (
    xs: 4rem,
    s: 5.6rem,
    l: 6.4rem,
  ));

  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.6;
  margin-top: 2.4rem;
  position: relative;

  &::after {
    background-color: rgba($color-black, 0.1);
    bottom: 0;
    content: '';
    height: 0.1rem;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 15rem;
  }

  @include mq($from: m) {
    font-size: 2.2rem;
  }
}

.article__content {
  font-size: 1.6rem;
  line-height: 1.6;

  > * + * {
    margin-top: 1.6rem;
  }

  > * + .headline {
    margin-top: 3.2rem;
  }

  > .headline {
    color: $color-green-1;
    margin-bottom: 2.4rem;
  }

  > hr {
    @include use-responsive-sizing(margin-bottom margin-top, (
      xs: 4rem,
      s: 5.6rem,
      l: 6.4rem,
    ));

    background-color: rgba($color-black, 0.1);
    clear: both;
    display: block;
    height: 0.1rem;
    margin-left: auto;
    margin-right: auto;
    width: 15rem;
  }

  > ul,
  > ul ul,
  > ol,
  > ol ol {
    counter-reset: list;
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
    padding-left: 4.8rem;
  }

  > ul li,
  > ol li {
    display: block;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }

  > ul li::before,
  > ol li::before {
    background-color: $color-green-1;
    color: #fff;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    height: 2rem;
    line-height: 2rem;
    margin-left: -4.8rem;
    margin-top: 0.4rem;
    padding-top: 0.1rem;
    position: absolute;
    text-align: center;
    width: 2rem;

    @include mq($from: m) {
      font-size: 1.6rem;
      height: 2.2rem;
      line-height: 2.2rem;
      width: 2.2rem;
    }
  }

  > ul li:nth-child(4n+1)::before,
  > ol li:nth-child(4n+1)::before {
    border-radius: 50%;
  }

  > ul li:nth-child(4n+2)::before,
  > ol li:nth-child(4n+2)::before {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
  }

  > ul li:nth-child(4n+4)::before,
  > ol li:nth-child(4n+4)::before {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  > ul li::before {
    content: ' ';
  }

  > ol li::before {
    content: counter(list);
    counter-increment: list;
  }

  > ul ul,
  > ul ol,
  > ol ul,
  > ol ol {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
  }

  > table {
    border-collapse: collapse;
    margin-bottom: 3.2rem;
    margin-top: 3.2rem;
    width: 100%;
  }

  > table th,
  > table td {
    padding: 0.4rem 1.2rem;
    text-align: left;
    vertical-align: text-top;
  }

  > table th {
    background-color: $color-green-2;
    color: $color-white;
    font-weight: bold;
  }

  > table tr:nth-child(even) {
    background-color: $color-green-4;
  }

  @include mq($from: m) {
    font-size: 1.9rem;
  }
}

.article__figure {
  clear: both;
}

.article__figure--full {
  @include use-responsive-sizing(margin-left margin-right, (
    s: column-offset(s, 1, 10, true),
    m: column-offset(m, 1, 10, true),
    l: column-offset(l, 1, 8, true),
    xl: column-offset(xl, 1, 8, true),
  ));

  @include use-responsive-sizing(margin-top margin-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
  ));
}

.article__figure--left,
.article__figure--right {
  @include use-responsive-sizing(width, (
    s: column-width(s, 5, 10),
    m: column-width(m, 5, 10),
    l: column-width(l, 4, 8),
    xl: column-width(xl, 4, 8),
  ));

  margin-bottom: 4rem;
  margin-top: 4rem;

  @include mq($from: s) {
    margin-top: 0;
  }
}

.article__figure--left {
  @include use-responsive-sizing(margin-right, $gaps, s);
  @include use-responsive-sizing(margin-left, (
    s: column-offset(s, 1, 10, true),
    m: column-offset(m, 1, 10, true),
    l: column-offset(l, 1, 8, true),
    xl: column-offset(xl, 1, 8, true),
  ));

  @include mq($from: s) {
    float: left;
  }
}

.article__figure--right {
  @include use-responsive-sizing(margin-left, $gaps, s);
  @include use-responsive-sizing(margin-right, (
    s: column-offset(s, 1, 10, true),
    m: column-offset(m, 1, 10, true),
    l: column-offset(l, 1, 8, true),
    xl: column-offset(xl, 1, 8, true),
  ));

  @include mq($from: s) {
    float: right;
  }
}

.article__module {
  @include use-break-out(false);
  @include use-responsive-sizing(margin-top margin-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
  ));

  &:first-child {
    margin-top: 0;
  }

  & + & {
    @include use-responsive-sizing(margin-top, (
      xs: -4rem,
      s: -5.6rem,
      m: -6.4rem,
    ));
  }
}
