.gallery {
  position: relative;
}

.gallery__images {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;

  &:focus {
    outline: 0;
  }

  &::after {
    @include use-outline(-10px, $color-white);

    content: '';
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    position: none;
    z-index: 5;
  }

  &:focus::after {
    opacity: 1;
  }

  &:focus:not(:focus-visible)::after {
    opacity: 0;
  }
}

.gallery__image {
  flex-shrink: 0;
  position: relative;
  width: 100%;
}

.gallery__navigation {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: space-between;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.gallery__navigation-button {
  color: $color-white;
  font-size: 4.8rem;
  line-height: 0;
  padding: 1.6rem;
  pointer-events: all;
  transition-property: background-color, opacity;

  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:not([disabled]):hover,
  &:focus {
    background-color: $color-black;
  }
}
