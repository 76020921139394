.breadcrumb {
  background-color: $color-teal-3;
  font-size: 1.4rem;
  line-height: 1.6;
}

.breadcrumb__inner {
  @include use-container();

  background-image: url('../../../images/breadcrumb.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: flex;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;

  @each $breakpoint, $padding in $inner-container-padding {
    @include mq($from: $breakpoint) {
      background-position: center right $padding;
    }
  }

  @include mq($from: rem2px($inner-container-max-width)) {
    background-position: center right $inner-container-max-padding;
  }
}

.breadcrumb__items {
  display: block;
  width: 100%;
}

.breadcrumb__item {
  display: inline;
}

.breadcrumb__link-icon {
  margin-right: 0.8rem;
  vertical-align: -0.15em;
}

.breadcrumb__icon {
  font-size: 0.9rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
