.logo-teaser {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &:focus-within {
    @include use-outline();
  }
}

.logo-teaser__image {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  &:focus {
    outline: 0;
  }
}

.logo-teaser__text {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6;
  margin-top: 1.6rem;
  text-align: center;
}

.logo-teaser__icon {
  font-size: 1.2rem;
  line-height: 0;
  margin-left: 0.6rem;
}
