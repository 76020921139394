.newsletter-breaker {
  @include use-responsive-sizing(padding-top padding-bottom, (
    xs: 4rem,
    s: 5.6rem,
    m: 6.4rem,
    l: 7.2rem,
    xl: 9.6rem,
  ));

  background-color: $color-teal-1;
  color: $color-white;
}

.newsletter-breaker__inner {
  @include use-container();
}

.newsletter-breaker__content {
  @include use-responsive-sizing(width, (
    l: column-width(l, 10),
    xl: column-width(xl, 10),
  ));

  margin-left: auto;
  margin-right: auto;

  @include mq($from: m) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.newsletter-breaker__headline {
  @include use-responsive-sizing(width, (
    m: 50%,
    l: column-width(l, 4, 10),
    xl: column-width(xl, 4, 10),
  ));

  flex-shrink: 0;
  margin-bottom: 4rem;

  @include mq($from: m) {
    margin-bottom: 0;
  }
}

.newsletter-breaker__form {
  @include use-responsive-sizing(width, (
    m: 50%,
    l: column-width(l, 6, 10),
    xl: column-width(xl, 6, 10),
  ));

  flex-shrink: 0;
}
