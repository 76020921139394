// Colors
$color-black: #000;
$color-white: #fff;
$color-teal-1: #008290;
$color-teal-2: #76ded2;
$color-teal-3: #d9f6f2;
$color-green-1: #00846f;
$color-green-2: #00a88d;
$color-green-3: #33c3ac;
$color-green-4: #f5fdfc;
$color-gray: #979797;
$color-red: #bd0838;

// Font families
$font-stack: 'Objectivity', Helvetica, Arial, sans-serif;

// Durations
$duration-default: 0.2s;
$duration-long: 0.4s;

// Z-Index
$z-index-list: 'skip-link', 'consent-banner', 'menu', 'header';

// mq()
$mq-responsive: true;
$root-font-size-px: 10px;

// Columns
$columns: 12;

// Gap
$gaps: (
  xs: 3rem,
  s: 3rem,
  m: 3.5rem,
  l: 4rem,
  xl: 5rem,
);

// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container
$inner-container-padding: (
  xs: 5vw,
  s: 5vw,
  m: 5vw,
  l: 5vw,
  xl: 5vw,
);

$inner-container-max-width: 144rem;
$inner-container-max-padding: (map-get($inner-container-padding, xl) / 100vw) * $inner-container-max-width;

// Exports
:export {
  l: map-get($mq-breakpoints, l);
  m: map-get($mq-breakpoints, m);
  s: map-get($mq-breakpoints, s);
  xl: map-get($mq-breakpoints, xl);
  xs: map-get($mq-breakpoints, xs);
}
